import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import useAuth from "../helpers/authentication.js";

export default function Pricing({ userData, headerColor }) {
  const {t} = useTranslation("common");

  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const [accessToken, setAccessToken] = React.useState(false);

  const navigate = useNavigate();

  const routeChange = (path) =>{ 
    navigate(path);
  }

  async function getAccessToken() {
    setAccessToken(await getAccessTokenSilently());
  }

  if (isAuthenticated && userData && !accessToken) {
    getAccessToken();
  }

  const tiers = [
    {
      title: t('pricing.oneMonth.title'),
      subheader: t('pricing.oneMonth.subheader'),
      price: '250',
      price_id: 'price_1Q4Olq16cMgJryGtmYk30iU8',
      description: [
        t('pricing.oneMonth.description.line1'),
        t('pricing.oneMonth.description.line2'),
        t('pricing.oneMonth.description.line3'),
      ],
      buttonText: t('pricing.oneMonth.button.label'),
      buttonVariant: 'outlined',
    },
    {
      title: t('pricing.twoMonth.title'),
      subheader: t('pricing.twoMonth.subheader'),
      price: '400',
      price_id: 'price_1Q4OzH16cMgJryGtHzszsHaL',
      description: [
        t('pricing.twoMonth.description.line1'),
        t('pricing.twoMonth.description.line2'),
        t('pricing.twoMonth.description.line3'),
      ],
      buttonText: t('pricing.twoMonth.button.label'),
      buttonVariant: 'contained',
    },
    {
      title: t('pricing.threeMonth.title'),
      subheader: t('pricing.threeMonth.subheader'),
      price: '500',
      price_id: 'price_1Q4OzH16cMgJryGt5qC6muIc',
      description: [
        t('pricing.threeMonth.description.line1'),
        t('pricing.threeMonth.description.line2'),
        t('pricing.threeMonth.description.line3'),
      ],
      buttonText: t('pricing.threeMonth.button.label'),
      buttonVariant: 'outlined',
    },
  ];
  

  return (
    <Container
      id="pricing"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color={headerColor}>
          {t('pricing.title')}
        </Typography>
        <Typography variant="body1" color={headerColor}>
          {t('pricing.description.line1')} <br />
          {t('pricing.description.line2')}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <Card
              sx={{
                minWidth: 270,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === t('pricing.twoMonth.title') ? '1px solid' : undefined,
                borderColor:
                  tier.title === t('pricing.twoMonth.title') ? 'primary.main' : undefined,
                background:
                  tier.title === t('pricing.twoMonth.title')
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === t('pricing.twoMonth.title') ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === t('pricing.twoMonth.title') && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === t('pricing.twoMonth.title') ? 'grey.50' : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    {tier.price}kr.
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === t('pricing.twoMonth.title')
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === t('pricing.twoMonth.title') ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions sx={{
                  display: 'inline-block'
                }}
              >
                {(accessToken ?
                  <form 
                    name="checkout" 
                    id="checkout-form" 
                    action="/create-checkout-session"
                    method="POST"
                  >
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      type='submit'
                    >
                      {tier.buttonText}
                    </Button>
                    <input name="customer_id" id="customer_id" type="hidden" value={userData.stripe_customer_id} />
                    <input name="price_id" id="price_id" type="hidden" value={tier.price_id} />
                  </form> :
                  <Button onClick={() => routeChange('/login')} fullWidth variant={tier.buttonVariant} >
                    {t('pricing.signUp.button.label')}
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
