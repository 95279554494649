// Importing modules
import * as React from 'react';
import {BrowserRouter , Routes , Route} from 'react-router-dom';
import useAuth from "./helpers/authentication";
import './App.css';
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import LinearProgress  from '@mui/material/LinearProgress';
import AppAppBar from './components/AppAppBar';
import SkeletonAppBar from './components/SkeletonAppBar';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import RecoverPage from './RecoverPage';
import HomeSearch from './HomeSearch';
import AccountSettings from './AccountSettings';
import Dashboard from './Dashboard';
import CreateOrganisation from './CreateOrganisation';
import PricingPage from './PricingPage';
import TOSPage from './TOSPage';

import BackgroundImage from './static/copenhagen-skyline-in-the-evening-denmark.jpg';
 
function App() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();

  const [t, i18n] = useTranslation('common');

  const [userData, setUserData] = React.useState(false);
  const [language, setLanguage] = React.useState('dk');
  const [activeSearch, setActiveSearch] = React.useState(false);

  const getUserData = async () => {
    if (isAuthenticated) {
      await fetch('/get-user-data', 
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) =>
        res.json().then((data) => {
          if (res.status !== 401 && res.status !== 422) {
            setUserData(data);
          }
        })
      );
    }
  };

  if (isAuthenticated && !userData) {
    getUserData();
  }

  React.useEffect(() => {
    const language = sessionStorage.getItem('language');
    if (language) {
      setLanguage(language);
      i18n.changeLanguage(language);
    }
  }, [i18n, setLanguage]);

  const setSessionLanguage = (language) => {
    sessionStorage.setItem('language', language);
    setLanguage(language);
    i18n.changeLanguage(language);
  }

  React.useEffect(() => {
    const search = JSON.parse(sessionStorage.getItem('activeSearch'));
    if (search) {
      setActiveSearch(search);
    }
  }, [setActiveSearch]);

  const setSessionSearch = (search) => {
    sessionStorage.setItem('activeSearch', JSON.stringify(search));
    setActiveSearch(search);
  }

  return (
    <div>
      {isAuthenticated && !userData ? (
        <Box sx={{ 
            minWidth: '100%',
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: "url(" + BackgroundImage + ")",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <SkeletonAppBar />
          <Box 
            sx={{ 
              minWidth: '100%',
              minHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <LinearProgress sx={{ width: '70%' }} />
          </Box>
        </Box>
      ): (
        <BrowserRouter>
          <AppAppBar userData={userData} language={language} setLanguage={setSessionLanguage} />
          <Routes>
            <Route exact path='/' element={<LandingPage userData={userData} />} />
            <Route exact path='/login' element={<LoginPage /> } />
            <Route exact path='/register' element={<RegistrationPage /> } />
            <Route exact path='/recover' element={<RecoverPage /> } />
            <Route exact path='/search' element={<HomeSearch activeSearch={activeSearch} setActiveSearch={setSessionSearch} userData={userData} getUserData={getUserData} />} />
            <Route exact path='/favorites' element={<HomeSearch activeSearch={activeSearch} setActiveSearch={setSessionSearch} userData={userData} getUserData={getUserData} showFavorites={true} />} />
            <Route exact path='/settings' element={<AccountSettings language={language} setLanguage={setSessionLanguage} userData={userData} getUserData={getUserData} /> } />
            <Route exact path='/dashboard' element={<Dashboard userData={userData} />} />
            <Route exact path='/create-organisation' element={<CreateOrganisation />} />
            <Route exact path='/pricing' element={<PricingPage userData={userData} />} />
            <Route exact path='/terms-of-service' element={<TOSPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
    // <div className="App">
    //     <header className="App-header">
    //         <h1>React and flask</h1>
    //         {/* Calling a data from setdata for showing */}
    //         <p>{data.name}</p>
    //         <p>{data.age}</p>
    //         <p>{data.date}</p>
    //         <p>{data.programming}</p>

    //     </header>
    // </div>
  );
}
 
export default App;
